/** packages */
import { fromZonedTime, toZonedTime } from 'date-fns-tz'
import { format, isAfter, isDate, sub } from 'date-fns'
import partition from 'lodash/partition'
import { formatDateRange } from '~/utils/date'

/** utils */
import {
  getReturnRoute,
  isBenchmarkMineralsHost,
} from '~/src/utils/wordpress-content'

/** types */
import type {
  TEvent,
  TEventFormat,
  TEventModality,
  TEventNode,
  TEventSession,
  TEventWebinar,
} from '~/src/types/events'
import { EVENT_MODALITY } from '../constants/events'

export const getEventsSinceLastYear = (events: TEvent[]) => {
  // Filter active events from the last 12 months with valid start and end dates
  const today = new Date()
  const oneYearAgoDate = sub(today, { years: 1 })
  return events.filter(
    (_event) =>
      isAfter(_event.startDateTimeUTC, oneYearAgoDate) &&
      isDate(_event.startDateTimeUTC) &&
      isDate(_event.endDateTimeUTC),
  )
}

export const getUpcomingEvents = (events: TEvent[]) => {
  const eventsPartitioned = partition(events, (event) => {
    const currentDateTime = new Date()
    return event.endDateTimeUTC > currentDateTime
  })
  return eventsPartitioned
    .at(0)
    .filter(
      (_event) =>
        isDate(_event.startDateTimeUTC) && isDate(_event.endDateTimeUTC),
    )
    .sort(
      (before, after) =>
        (before.startDateTimeUTC?.getTime() || 0) -
        (after.startDateTimeUTC?.getTime() || 0),
    )
}

export const getPastEvents = (events: TEvent[]) => {
  const eventsPartitioned = partition(events, (event) => {
    const currentDateTime = new Date()
    return !event.endDateTime || event.endDateTimeUTC > currentDateTime
  })
  return eventsPartitioned
    .at(1)
    .sort(
      (before, after) =>
        (after.startDateTimeUTC?.getTime() || 0) -
        (before.startDateTimeUTC?.getTime() || 0),
    )
}

export const processEvents = (events: TEventNode[]) => {
  return events
    ?.map((event) => formatEvent(event))
    .sort(
      (a, b) =>
        (a.startDateTimeUTC?.getTime() || 0) -
        (b.startDateTimeUTC?.getTime() || 0),
    )
}

export const formatEvent = (event: TEventNode): TEvent => {
  const _event: TEvent = {}
  const url = event.events.url
  const timeZone = event.events.timeZone?.at(0)
  const startDateTime = event.events.startDateTime
  const endDateTime = event.events.endDateTime
  const location = event.events.location
  const sourceUrl = event.events.backgroundImage?.node?.sourceUrl
  const isFeatured = event.isFeatured
  const title = event.title || event.events.title
  const eventFormat = event.events?.format?.at(0) as TEventFormat

  _event.id = event.id
  _event.modality = event.events.modality
    ?.at(0)
    ?.toLowerCase() as TEventModality
  _event.url = getReturnRoute(url) ?? ''
  _event.slug = event.slug ?? null
  if (!isBenchmarkMineralsHost(url)) {
    _event.target = '_blank'
  }
  _event.startDateTime = startDateTime || endDateTime
  _event.endDateTime = endDateTime || startDateTime
  _event.backgroundImage = sourceUrl ?? null
  _event.location = location
  _event.timeZone = timeZone
  _event.isFeatured = isFeatured
  _event.status = event.status
  _event.title = title
  _event.format = eventFormat
  _event.blurb = event.events.blurb
  _event.formId = event.events.formId

  // We need to check if the date is null, because new Date(null) returns a valid date => '1970-01-01T00:00:00.000Z'
  if (startDateTime) {
    _event.startDateTimeUTC = fromZonedTime(new Date(startDateTime), timeZone)
    _event.monthYear = format(_event.startDateTimeUTC, 'MMMM yyyy')
    _event.year = format(_event.startDateTimeUTC, 'yyyy')
  }

  if (endDateTime) {
    _event.endDateTimeUTC = fromZonedTime(new Date(endDateTime), timeZone)
  }
  const _webinars: TEventWebinar[] = []
  event.events.webinars?.forEach((webinar) => {
    const _location = location ?? eventFormat
    const _timeZone = webinar.timeZone?.at(0) ?? timeZone
    const _startDateTimeUTC =
      webinar.startDateTime &&
      fromZonedTime(new Date(webinar.startDateTime), _timeZone)

    const _endDateTimeUTC =
      webinar.endDateTime &&
      fromZonedTime(new Date(webinar.endDateTime), _timeZone)
    const _sessions = (webinar.sessions || []) as TEventSession[]
    _webinars.push({
      ...webinar,
      location: _location,
      startDateTime: webinar.startDateTime,
      endDateTime: webinar.endDateTime,
      startDateTimeUTC: _startDateTimeUTC,
      endDateTimeUTC: _endDateTimeUTC,
      timeZone: _timeZone,
      sessions: _sessions,
    })
  })
  return {
    ..._event,
    webinars: _webinars,
  }
}

export const formatEventDateRange = (event: TEvent, dayFormat = '') => {
  if (!event.startDateTime || !event.endDateTime) {
    return ''
  }

  const dateRange = formatDateRange(
    toZonedTime(event.startDateTime, 'UTC'),
    toZonedTime(event.endDateTime, 'UTC'),
    dayFormat,
  )

  if (event.modality === EVENT_MODALITY.ONLINE) {
    return `${dateRange} UTC`
  }

  return dateRange
}
