export default defineNuxtRouteMiddleware((to) => {
  /**
   * Validate that we are not on the home page and remove the slash (/)
   * if the url has it at the end.
   */
  if (to.name === 'index' || !to.path?.endsWith('/')) return

  const params = to.fullPath?.split('?')[1]
  let route = to.path?.replace(/\/$/, '')

  if (params) route = `${route}?${params}`

  return navigateTo(route, { redirectCode: 301 })
})
