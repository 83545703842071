import { format } from 'date-fns'
import { random } from '~/utils/random'
import { reformat } from '~/utils/date'
import { formatVideoDuration } from '~/utils/time'
import { SUBSCRIPTIONS } from '@/constants/memberships'
import { ARTICLE_COVERS } from '~/constants/market-insights'
import {
  COPPER,
  ESG,
  FORECASTS,
  GIGAFACTORY_ASSESSMENT,
  LITHIUM_ION_BATTERIES,
  MARKET_ASSESSMENTS,
  PA_SUBSCRIPTION_PREFIX,
  PRECURSOR_ASSESSMENT,
  PRICE_ASSESSMENTS,
  SODIUM_ION_BATTERIES,
  SOURCE,
} from '~/constants/services/constants'

import { priceTab } from '~/constants/dummyData/priceTab'
import CryptoJS from 'crypto-js'

import type { TAllPricesChart } from '~/types/all-prices-chart'
import type {
  TArticleNode,
  TArticlesResponse,
} from '~/src/types/wordpress-content'
import type { TGeneral } from '~/src/types/common'
import type { TEventHero, TEventNode } from '~/src/types/events'
import { getReturnRoute } from '~/src/utils/wordpress-content'
import type { TPriceRaw, TPriceResponse } from '~/src/types/prices-chart'

const VIDEO_IMG_FALLBACK =
  'https://res.cloudinary.com/benchmarkminerals/image/upload/v1678209404/assets/request-for-proposal_mq7uco.jpg'

const keyMapping = {
  category: 'category.name',
  purity: 'purity.name',
  shippingRoute: 'shippingRoute.name',
  meshSize: 'meshSize.size',
  format: 'format.name',
  feedstock: 'feedstock.name',
  product: 'product.name',
}

const getRandomArticleCover = () => {
  const randomIndex = Math.floor(random() * ARTICLE_COVERS.length)
  return ARTICLE_COVERS[randomIndex]
}

export function getAdaptedPost(article: TArticleNode | TGeneral) {
  const { id, slug } = article
  const backgroundImage =
    article.featuredImage?.node?.mediaItemUrl ?? getRandomArticleCover()

  return {
    ...article,
    slug: slug || id,
    backgroundImage,
  }
}

export function getAdaptedPosts({ nodes }: TArticlesResponse | TGeneral) {
  return nodes.map((article) => getAdaptedPost(article))
}

function getAdaptedSubscription(subs) {
  const { name, slug, status } = subs
  let expirationDate = subs.expiration_date

  if (status === 'active' && expirationDate === 'none') {
    expirationDate = 'Never'
  } else if (expirationDate !== 'none') {
    expirationDate = format(Date.parse(expirationDate), 'dd/MM/yyyy')
  }

  return {
    name,
    slug,
    status,
    expirationDate,
  }
}

const membershipNames = {
  Gigafactory: GIGAFACTORY_ASSESSMENT.menu,
  'Battery Cell': LITHIUM_ION_BATTERIES.menu,
  'Battery Database': LITHIUM_ION_BATTERIES.menu,
  Precursor: PRECURSOR_ASSESSMENT.menu,
  'Sodium Ion Battery': SODIUM_ION_BATTERIES.menu,
  'Service: Copper': COPPER.menu,
}

const getMembershipName = (name: string): string => {
  const newName: string = membershipNames[name]
  return newName || name
}

export function getAdaptedMembershipStatuses(membershipStatuses) {
  const productsAndServices = {}
  const productNames = {
    Pricing: PRICE_ASSESSMENTS.name,
    Forecast: FORECASTS.name,
    'Market-Assessment': MARKET_ASSESSMENTS.name,
    ESG: ESG.name,
  }

  membershipStatuses.forEach((membership) => {
    const splittedName = membership.name.split(': ')
    let product

    if (splittedName.length > 1) {
      product = splittedName[0]
      membership.name = splittedName[1]
    } else {
      product = 'Source'
    }

    membership.name = getMembershipName(membership.name)

    if (productNames[product]) {
      product = productNames[product]
    }

    if (!productsAndServices[product]) {
      productsAndServices[product] = []
    }

    productsAndServices[product].push(getAdaptedSubscription(membership))
  })

  const productsAndServicesData = setProductsAndServices(productsAndServices)

  return getResponseForProductsAndServices(productsAndServicesData)
}

function setProductsAndServices(productsAndServices) {
  for (const [key, value] of Object.entries(SUBSCRIPTIONS)) {
    if (!productsAndServices[key]) {
      productsAndServices[key] = [...value]
      continue
    }

    value.forEach((subscription) => {
      if (
        !productsAndServices[key].some((sub) => sub.name === subscription.name)
      ) {
        productsAndServices[key].push(subscription)
      }
    })
  }
  return productsAndServices
}

function getResponseForProductsAndServices(productsAndServices) {
  return {
    [PRICE_ASSESSMENTS.name]: productsAndServices[PRICE_ASSESSMENTS.name],
    [FORECASTS.name]: productsAndServices[FORECASTS.name],
    [SOURCE.name]: productsAndServices[SOURCE.name],
    [MARKET_ASSESSMENTS.name]: productsAndServices[MARKET_ASSESSMENTS.name],
    [ESG.name]: productsAndServices[ESG.name],
  }
}

export function getAdaptedTickerIndexes(indexes) {
  return indexes?.map((index) => {
    const data = []
    const dates = []
    index.oneYearIndexTrend.forEach((index) => {
      data.push(index.value)
      dates.push(index.date)
    })
    const chartData = { data, dates }
    return {
      name: index.label,
      values: {
        today: index.today,
        last: index.last,
        percentageChange: index.percentageChange,
        publishedDate: index.publishedDate,
        hasAccess: index.hasAccess,
        updateFrequency: index.updateFrequency,
        updateFrequencyText: index.updateFrequencyText,
        url: index.url,
        yearOnYear: index.yearOnYear,
        yearToDate: index.yearToDate,
        subscription: index.subscription,
        chartData,
      },
    }
  })
}

export function getAdaptedRegions(regions) {
  return regions.map((region) => {
    return {
      name: region.label,
      selected: true,
    }
  })
}

export function getAdaptedAllPricesDataChart(response) {
  const groupedData: any = {}

  response.forEach((product) => {
    const ProductName = product.$metadata.product

    product.data.forEach((item) => {
      const unitOfMeasureId = item.unitOfMeasure.id

      if (!groupedData[unitOfMeasureId]) {
        groupedData[unitOfMeasureId] = {
          unitOfMeasure: item.unitOfMeasure,

          data: [],

          currency: item.currency,
        }
      }

      groupedData[unitOfMeasureId].data.push({
        ...item,

        Product: { ProductName },
      })
    })
  })

  return <TAllPricesChart>(
    Object.fromEntries(
      Object.entries(groupedData).map(([_, value], index) => [
        `y${index + 1}`,

        value,
      ]),
    )
  )
}

export function transformIndexToPricesStructure(indexStructure) {
  return {
    productName: `${indexStructure.product} Index`,
    subscription: '',
    label: `${indexStructure.product} Index`,
    classTextColor: '',
    frequency: '1M',
    isSustainable: false,
    values: indexStructure.indexes.summary.map((item) => ({
      currency: item.data.unit ? item.data?.unit : null,
      unitMeasure: '',
      name: item.product,
      today: item.data.today,
      last: item.data.last,
      latestChange: item.data.latestChange
        ? item.data.latestChange?.value
        : null,
      yearToDate: item.data.yearToDate ? item.data.yearToDate?.value : null,
      yearOnYear: item.data.yearOnYear ? item.data.yearOnYear?.value : null,
      isPublic: true,
      pillColor: '',
      classTextColor: '',
      isSustainable: false,
    })),
  }
}

interface SummaryColumn {
  header: string
  key: string
  type: string
  isFrequency: boolean
}

const SUMMARY_DEFAULT_COLUMNS = (frequency): SummaryColumn[] => [
  {
    header: 'Current',
    key: 'today',
    type: 'price',
    isFrequency: false,
  },
  {
    header: frequency,
    key: 'latestChange',
    type: 'index',
    isFrequency: true,
  },
  {
    header: 'YOY',
    key: 'yearOnYear',
    type: 'index',
    isFrequency: false,
  },
  {
    header: 'YTD',
    key: 'yearToDate',
    type: 'index',
    isFrequency: false,
  },
]

const SUMMARY_PRICE_DAILY_COLUMNS = [
  {
    header: 'Current',
    key: 'today',
    type: 'price',
    isFrequency: false,
  },
  {
    header: '1D',
    key: 'latestChange',
    type: 'index',
    isFrequency: true,
  },
  {
    header: '1WK',
    key: 'weeklyChange',
    type: 'index',
    isFrequency: false,
  },
  {
    header: '1M',
    key: 'monthlyChange',
    type: 'index',
    isFrequency: false,
  },
]

const PRICE_DAILY_INDICATOR_NAME = 'Daily Price Indicator'
const PRICE_DAILY_INDICATOR_PRICE_TYPE_NAME = 'Daily Price Indicator'

export function getAdaptedSummaryTable({
  summaries,
  authorized = false,
  columns,
}: {
  summaries: any[]
  authorized?: boolean
  columns?: SummaryColumn[]
}) {
  return summaries.map((summary) => {
    const isPriceDailyIndicator = summary.series.some(
      (item) => item.priceType.name === PRICE_DAILY_INDICATOR_PRICE_TYPE_NAME,
    )
    const defaultColumns = isPriceDailyIndicator
      ? SUMMARY_PRICE_DAILY_COLUMNS
      : SUMMARY_DEFAULT_COLUMNS(summary.frequency)
    return {
      productName: summary.productName,
      columns: columns ?? defaultColumns,
      authorized:
        summary.authorized === undefined ? authorized : summary.authorized,
      subscription: summary.subscription,
      label: summary.name,
      classTextColor: summary.classTextColor,
      frequency: summary.frequency,
      isSustainable: summary.isSustainable,
      values: summary.series.map((serie) => ({
        currency: serie.currency?.symbol || '',
        unitMeasure: serie.unitOfMeasure.symbol,
        name: serie.name,
        today: serie.data.today,
        last: serie.data.last,
        latestChange: serie.data.latestChange
          ? serie.data.latestChange?.value
          : null,
        yearToDate: serie.data.yearToDate ? serie.data.yearToDate?.value : null,
        yearOnYear: serie.data.yearOnYear ? serie.data.yearOnYear?.value : null,
        weeklyChange: serie.data.weeklyChange
          ? serie.data.weeklyChange?.value
          : null,
        monthlyChange: serie.data.monthlyChange
          ? serie.data.monthlyChange?.value
          : null,
        isPublic: serie.isPublic,
        pillColor: serie.pillColor,
        classTextColor: serie.classTextColor,
        isSustainable: summary.isSustainable,
        priceType: serie.priceType,
      })),
    }
  })
}

const getSummariesWithPriceDailyIndicator = (summaries) => {
  const filteredSummaries = summaries
    .filter((summary) =>
      summary.series.some(
        (serie) =>
          serie.priceType.name === PRICE_DAILY_INDICATOR_PRICE_TYPE_NAME,
      ),
    )
    .map((summary) => {
      const series = summary.series.filter(
        (serie) =>
          serie.priceType.name === PRICE_DAILY_INDICATOR_PRICE_TYPE_NAME,
      )
      return { ...summary, series }
    })
    .map((summary) => ({ ...summary, name: PRICE_DAILY_INDICATOR_NAME }))

  return getAdaptedSummaryTable({
    summaries: filteredSummaries,
    columns: SUMMARY_PRICE_DAILY_COLUMNS,
  }).map((summary) => {
    const values = summary.values.map((value) => ({ ...value, dotted: true }))

    return { ...summary, values }
  })
}

const getSummariesWithoutPriceDailyIndicator = (summaries) => {
  const filteredSummaries = summaries
    .map((summary) => {
      const series = summary.series.filter(
        (serie) =>
          serie.priceType.name !== PRICE_DAILY_INDICATOR_PRICE_TYPE_NAME,
      )
      return { ...summary, series }
    })
    .filter((summary) => summary.series.length > 0)
  return getAdaptedSummaryTable({ summaries: filteredSummaries })
}

export function getAdaptedSummaries(summaries) {
  const allSummaries = summaries
    .map((summary) => {
      summary.data.forEach((data) => {
        data.subscription = getPASubscription(summary.$metadata.product)
        data.productName = summary.$metadata.product
        data.authorized = summary.authorized
      })
      return summary.data
    })
    .flat()

  return {
    summariesDailyPriceIndicator:
      getSummariesWithPriceDailyIndicator(allSummaries),
    summaries: getSummariesWithoutPriceDailyIndicator(allSummaries),
  }
}

function getPASubscription(product) {
  let slug = product.toLowerCase().replace(/ /g, '-')
  if (slug === 'lithium-ion-batteries') slug = 'battery-cell'
  return `${PA_SUBSCRIPTION_PREFIX}${slug}`
}

export function getAdaptedPrices(response: TPriceResponse): TPriceRaw {
  const ProductName = response.$metadata.product
  const groupedData = {}
  response.data.forEach((item) => {
    const priceType = item.unitOfMeasure.name
    if (!groupedData[priceType]) {
      groupedData[priceType] = {
        data: [],
        unitOfMeasure: item.unitOfMeasure,
        currency: item.currency,
      }
    }
    groupedData[priceType].data.push({
      ...item,
      hash: generateHashForDataSeries(item),
      Product: {
        ProductName,
      },
    })
  })

  return Object.fromEntries(
    Object.entries(groupedData).map(([_, value], index) => [
      `y${index + 1}`,
      value,
    ]),
  ) as TPriceRaw
}

export const generateHashForDataSeries = (data: any) => {
  const combinedString = [
    data?.meshSize?.size,
    data?.product?.name,
    data?.feedstock?.name,
    data?.category?.name,
    data?.purity?.name,
    data?.shippingRoute?.name,
    data?.grade,
    data?.unitOfMeasure?.name,
    data?.currency?.name,
    data?.priceType?.name,
    data?.format?.name,
  ]
    .join('')
    .toLowerCase()
    .trim()
  return CryptoJS.SHA256(combinedString).toString(CryptoJS.enc.Hex)
}

// Video Adapters
const TIME_FORMAT = 'dd MMM yyyy'

function removeAnchorWithHref(text) {
  const anchorTagRegexInit = /<a[^>]*href="[^"]*"[^>]*>/gi
  const sanitizeTextInit = text?.replaceAll(anchorTagRegexInit, '')
  const anchorTagRegexEnd = /<\/a>/gi
  return sanitizeTextInit?.replaceAll(anchorTagRegexEnd, '')
}

function getAdaptedVideo(video) {
  const { id, slug, title, date } = video
  const { previewVimeoId, speaker, vimeoId, isPublic, description } =
    video.videos
  const categories = video.categories?.nodes
  const tags = video.tags?.nodes

  let publishedDate = ''
  try {
    publishedDate = reformat(date, TIME_FORMAT)
  } catch {
    try {
      const _date = new Date(date)

      publishedDate = format(_date, TIME_FORMAT)
    } catch {
      /* empty */
    }
  }

  const backgroundImage =
    video.videos.thumbnail?.node?.mediaItemUrl || VIDEO_IMG_FALLBACK
  const duration = video.videos.duration

  return {
    id,
    contentType: 'video',
    publishedDate,
    duration: duration ? formatVideoDuration(duration) : '',
    slug: slug || id,
    title,
    excerpt: removeAnchorWithHref(speaker || ''),
    description,
    backgroundImage,
    categories,
    vimeoId: vimeoId || 0,
    previewVimeoId: previewVimeoId || 0,
    isPublic,
    tags,
    relatedVideos: [],
    date,
  }
}

export function getAdaptedVideos(videos) {
  return videos.map((video) => getAdaptedVideo(video))
}

export const getAdaptedArticles = (articles, format = TIME_FORMAT) => {
  return articles
    .map((article) => {
      const publishedDate = reformat(article?.date, format)
      const backgroundImage =
        article?.featuredImage?.node?.mediaItemUrl ?? getRandomArticleCover()
      const isExternal = article?.__typename === 'Membership'
      const url = isExternal ? `article/${article?.slug}` : null
      const readingTime = article?.memberships?.estimatedReadingTime ?? null
      const categories = article?.categories?.nodes || []

      return {
        publishedDate,
        slug: article?.slug || article?.id,
        title: article?.title,
        url,
        readingTime,
        excerpt: removeAnchorWithHref(article?.excerpt),
        backgroundImage,
        categories,
      }
    })
    .sort(
      (a, b) =>
        new Date(b.publishedDate).getTime() -
        new Date(a.publishedDate).getTime(),
    )
}

const getAdaptedEvents = (events: TEventNode[]) => {
  return events.map(({ slug, events }) => ({
    slug,
    backgroundImage: events.backgroundImage?.node?.sourceUrl,
    url: getReturnRoute(events.url) ?? '',
    modality: events.modality?.at(0) ?? '',
    format: events?.format?.at(0) ?? '',
  })) as TEventHero[]
}

const getAdaptedMarketInsightsArticles = (marketInsights) => {
  const sourceArticles = getAdaptedArticles(marketInsights.articles.nodes)
  const analysisArticles = getAdaptedArticles(
    marketInsights?.analysisArticles?.nodes || [],
  )

  const firstSourceArticle = sourceArticles.length ? [sourceArticles[0]] : []
  const firstAnalysisArticle = analysisArticles.length
    ? [analysisArticles[0]]
    : []
  const restSourceArticles = sourceArticles.length
    ? sourceArticles.slice(1, sourceArticles.length)
    : []

  return [
    ...firstSourceArticle,
    ...firstAnalysisArticle,
    ...restSourceArticles,
  ].slice(0, 5)
}

export const getAdaptedMarketInsights = (marketInsights) => {
  if (!marketInsights) return null
  const articles = getAdaptedMarketInsightsArticles(marketInsights)
  const events = getAdaptedEvents(marketInsights.events?.nodes || [])
  const videos = getAdaptedVideos(marketInsights.videos?.nodes || [])

  return { articles, events, videos }
}

export const getAdaptedPriceTab = (response) => {
  const ProductName = response.product
  const groupedData: any = {}
  if (response.prices?.chart?.length) {
    response.prices.chart.forEach((item) => {
      const { unitOfMeasure, currency } = item
      const priceType = unitOfMeasure.name
      if (!groupedData[priceType]) {
        groupedData[priceType] = {
          data: [],
          unitOfMeasure,
          currency,
        }
      }
      groupedData[priceType].data.push({
        ...item,
        hash: generateHashForDataSeries(item),
        Product: {
          ProductName,
        },
      })
    })
  } else {
    groupedData.tonne = priceTab
  }

  return Object.fromEntries(
    Object.entries(groupedData).map(([_, value], index) => [
      `y${index + 1}`,
      value,
    ]),
  )
}

export function getAdaptedFormidableErrors({ errors, form }) {
  const messages = {}

  for (const fieldId in errors) {
    const field = form.value.fields.find((_field) => _field.id === fieldId)
    messages[field.name] = errors[fieldId]
  }

  return messages
}

export function findMatches(detailedArray, simplifiedArray) {
  return detailedArray.map((detailedObj) => {
    const hasMatch = simplifiedArray.some((simplifiedObj) => {
      return Object.keys(simplifiedObj).every((key) => {
        const mappedKey = keyMapping[key] || key
        return simplifiedObj[key] === getNestedValue(detailedObj, mappedKey)
      })
    })

    return {
      ...detailedObj,
      hidden: !hasMatch,
    }
  })
}

function getNestedValue(obj, keyPath) {
  return keyPath.split('.').reduce((acc, key) => {
    return acc !== null && acc !== undefined ? acc[key] : undefined
  }, obj)
}
