import { gql } from 'graphql-tag'
import { getAllowedPostStatuses } from '~/src/utils/wordpress-content'

const buildEventsWithDateQuery = ({ includeNonPublished }) => {
  return gql`
    query getEvents {
      events(
        first: 5
        where: {
          stati: [${getAllowedPostStatuses(includeNonPublished)}],
          showOnlyFeaturedPosts: true
        }
      ) {
        nodes {
          slug
          isFeatured
          id
          status
          events {
            timeZone
            format
            title
            blurb
            url
            startDateTime
            endDateTime
            modality
            format
            location
            backgroundImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `
}

export default buildEventsWithDateQuery
