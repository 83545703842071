import revive_payload_client_OXiRJGo9hK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kJRuO5Zxd6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1aDrd3QP7H from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_bdJulzszQI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.1.0_sass@1.77_pbysurhci5y2nvdfzsasutvf2u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_MQDhhZrGQq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1LKwENVy9S from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Vo9AMfHubp from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ykOo9sHk3o from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_7Gb1qDpnix from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_inpAznHEJQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fb6aaDY6Ti from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-graphql-request@8.1.0_graphql@16.9.0_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-graphql-request/dist/runtime/plugin.js";
import _0_catch_errors_and_exceptions_client_jEVKhqGlRS from "/opt/buildhome/repo/plugins/0.catch-errors-and-exceptions.client.ts";
import _0_performance_observer_client_a6nMBXF0cp from "/opt/buildhome/repo/plugins/0.performance-observer.client.ts";
import _01_axios_trfC49DPAq from "/opt/buildhome/repo/plugins/01.axios.ts";
import _01_cookies_rVe02PKG6J from "/opt/buildhome/repo/plugins/01.cookies.ts";
import _01_http_client_0TwRmrGRoK from "/opt/buildhome/repo/plugins/01.http-client.ts";
import _02_rest_client_fJWj9SMMdq from "/opt/buildhome/repo/plugins/02.rest-client.ts";
import _03_hubspot_identity_client_ZVXR2lrEtt from "/opt/buildhome/repo/plugins/03.hubspot-identity.client.ts";
import _03_membershipApi_JTkBJU5CoA from "/opt/buildhome/repo/plugins/03.membershipApi.ts";
import _04_hubspot_live_chat_client_MYdZrEyANh from "/opt/buildhome/repo/plugins/04.hubspot-live-chat.client.ts";
import _04_rudderstack_client_62Efzp39oG from "/opt/buildhome/repo/plugins/04.rudderstack.client.ts";
import _05_bm_auth_GFs98Pzbun from "/opt/buildhome/repo/plugins/05.bm-auth.ts";
import all_prices_47DtI7My73 from "/opt/buildhome/repo/plugins/all-prices.ts";
import assets_zoKpJcbSia from "/opt/buildhome/repo/plugins/assets.ts";
import browser_client_kZsmP6IGPw from "/opt/buildhome/repo/plugins/browser.client.ts";
import capacity_database_Ak8crLfnjm from "/opt/buildhome/repo/plugins/capacity-database.ts";
import chartjs_1wC9lAM0Tp from "/opt/buildhome/repo/plugins/chartjs.ts";
import cloudinary_SEKWrTzwvn from "/opt/buildhome/repo/plugins/cloudinary.ts";
import date_range_picker_client_Mc4OTv0d9R from "/opt/buildhome/repo/plugins/date-range-picker.client.ts";
import download_manager_gThQnK2QhW from "/opt/buildhome/repo/plugins/download-manager.ts";
import file_pond_client_bWiN9gXVf8 from "/opt/buildhome/repo/plugins/file-pond.client.ts";
import formidable_form_builder_hwSBMcQiVW from "/opt/buildhome/repo/plugins/formidable-form-builder.ts";
import google_analytics_client_KruZkwwzId from "/opt/buildhome/repo/plugins/google-analytics.client.ts";
import modal_UEFnUXzgTX from "/opt/buildhome/repo/plugins/modal.ts";
import polyfill_client_aOiFD9Uk19 from "/opt/buildhome/repo/plugins/polyfill.client.ts";
import sentry_client_config_o5jjUx2pcO from "/opt/buildhome/repo/plugins/sentry.client.config.ts";
import theme_zupea9J243 from "/opt/buildhome/repo/plugins/theme.ts";
import toast_client_9z8IIRO0Dx from "/opt/buildhome/repo/plugins/toast.client.ts";
import vee_validate_client_P40JVl0wNI from "/opt/buildhome/repo/plugins/vee-validate.client.ts";
import velocity_client_4v06MdIA9C from "/opt/buildhome/repo/plugins/velocity.client.ts";
import vimeo_client_BFAm9LGErL from "/opt/buildhome/repo/plugins/vimeo.client.ts";
import watermark_client_Pmt0XmQZQ8 from "/opt/buildhome/repo/plugins/watermark.client.ts";
import backend_version_watcher_client_iqGYd6okqm from "/opt/buildhome/repo/plugins/backend-version-watcher.client.ts";
import graphql_client_9Pa4A2KAi9 from "/opt/buildhome/repo/plugins/graphql-client.ts";
export default [
  revive_payload_client_OXiRJGo9hK,
  unhead_kJRuO5Zxd6,
  router_1aDrd3QP7H,
  _0_siteConfig_bdJulzszQI,
  payload_client_MQDhhZrGQq,
  navigation_repaint_client_1LKwENVy9S,
  check_outdated_build_client_Vo9AMfHubp,
  chunk_reload_client_ykOo9sHk3o,
  plugin_vue3_7Gb1qDpnix,
  components_plugin_KR1HBZs4kY,
  prefetch_client_inpAznHEJQ,
  plugin_fb6aaDY6Ti,
  _0_catch_errors_and_exceptions_client_jEVKhqGlRS,
  _0_performance_observer_client_a6nMBXF0cp,
  _01_axios_trfC49DPAq,
  _01_cookies_rVe02PKG6J,
  _01_http_client_0TwRmrGRoK,
  _02_rest_client_fJWj9SMMdq,
  _03_hubspot_identity_client_ZVXR2lrEtt,
  _03_membershipApi_JTkBJU5CoA,
  _04_hubspot_live_chat_client_MYdZrEyANh,
  _04_rudderstack_client_62Efzp39oG,
  _05_bm_auth_GFs98Pzbun,
  all_prices_47DtI7My73,
  assets_zoKpJcbSia,
  browser_client_kZsmP6IGPw,
  capacity_database_Ak8crLfnjm,
  chartjs_1wC9lAM0Tp,
  cloudinary_SEKWrTzwvn,
  date_range_picker_client_Mc4OTv0d9R,
  download_manager_gThQnK2QhW,
  file_pond_client_bWiN9gXVf8,
  formidable_form_builder_hwSBMcQiVW,
  google_analytics_client_KruZkwwzId,
  modal_UEFnUXzgTX,
  polyfill_client_aOiFD9Uk19,
  sentry_client_config_o5jjUx2pcO,
  theme_zupea9J243,
  toast_client_9z8IIRO0Dx,
  vee_validate_client_P40JVl0wNI,
  velocity_client_4v06MdIA9C,
  vimeo_client_BFAm9LGErL,
  watermark_client_Pmt0XmQZQ8,
  backend_version_watcher_client_iqGYd6okqm,
  graphql_client_9Pa4A2KAi9
]