/** utils */
import {
  canPreviewContentByRoles,
  getUserRolesByPayload,
} from '~/src/utils/auth'

/** types */
import type { TUser, TUserResponse } from '~/src/types/auth'

export function getAdaptedUser(user: TUserResponse): TUser {
  const roles = getUserRolesByPayload(user.roles)
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    company: user.userData?.company || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    databaseId: user.databaseId,
    roles,
    canPreviewContent: canPreviewContentByRoles(roles),
  }
}
