export const EVENT_FORMAT = {
  BENCHMARK_WEEK: 'Benchmark Week',
  SUMMITS: 'Summits',
  WEBINARS: 'Webinars',
  WORLD_TOURS: 'World Tours',
} as const

export const EVENT_MODALITY = {
  ONLINE: 'online',
  IN_PERSON: 'in person',
} as const

export const EVENTS_LOGO =
  'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_auto,dpr_auto/v1732659743/assets/benchmark-events-logo_ikapaq.png?_i=AA'

export const EVENTS_FALLBACK_IMAGE =
  'https://res.cloudinary.com/benchmarkminerals/image/upload/w_800/f_avif,q_auto,g_auto,dpr_auto,h_300,c_fill/v1678804229/assets/home/VideoBannerHome_npgzv9.jpg?_i=AA'

export const EVENT_PREVIEW_CARD_FALLBACK_IMAGE = {
  ONLINE:
    'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_500,c_fill,g_auto/v1689289325/assets/events/benchmark-webinar_tickie.png?_i=AA',
  DEFAULT:
    'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_500,c_fill,g_auto/v1675204448/assets/events/event-fallback-in-person_kbldff.png?_i=AA',
} as const
