import {
  NON_PUBLISHED_POST_STATES,
  PUBLISHED_POST_STATES,
} from '~/src/constants/wordpress-content'
import { BENCHMARK_MINERALS_URLS } from '~/src/constants/common'

export function getAllowedPostStatuses(includeNonPublished: boolean) {
  const states = [...PUBLISHED_POST_STATES]

  if (includeNonPublished) {
    states.push(...NON_PUBLISHED_POST_STATES)
  }

  return states
}

export const isBenchmarkMineralsHost = (url?: string) => {
  if (!url) return false

  return BENCHMARK_MINERALS_URLS.some((_route) => url.includes(_route))
}

export const getReturnRoute = (url?: string) => {
  try {
    /**
     * in this part, we validate if a link is an internal link of benchmarkminerals.com
     * to place the appropriate host depending on the environment in which the application is running.
     */

    if (!isBenchmarkMineralsHost(url)) return url

    let route = url
    BENCHMARK_MINERALS_URLS.forEach((_route) => {
      const splitRoute = url.split(_route)

      if (splitRoute.length > 1) {
        route = url.split(_route)[1]
      }
    })

    return route
  } catch {
    return url
  }
}
