import type { RouterConfig } from '@nuxt/schema'

/*
  Custom router options
  https://nuxt.com/docs/guide/recipes/custom-routing#using-approuteroptions
*/
export default {
  scrollBehavior(to, from, savedPosition) {
    const bothAreTabs = !!(to.meta?.isTab && from.meta?.isTab)

    const withSameLayout = to.matched[0]?.path === from.matched[0]?.path

    if (bothAreTabs && withSameLayout) {
      return savedPosition
    }

    return { top: 0, left: 0 }
  },
} satisfies RouterConfig
